import { sha256 } from 'js-sha256';
import chroma from 'chroma-js';

const colors = [
  '#052f5f',
  '#774936',
  '#2280bf',
  '#4d908e',
  '#f4a261',
  '#e76f51',
  '#f72585',
  '#941c2f',
  '#242424',
];

const getCaseReportTagColors = (tag: string) => {
  const hash = sha256(tag);
  const hashInt = parseInt(hash.substring(0, 8), 16);
  const color = colors[hashInt % colors.length];
  return {
    color,
    bgColor: chroma(color).alpha(0.1).hex(),
  };
};

export default getCaseReportTagColors;
