import React from 'react';
import { Column, Row } from 'react-table';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { CaseReport } from '../../model/entities/case-report.entity';
import getInitialsFromName from '../../utils/get-initials-from-name/get-initials-from-name';
import formatDateLocalized, {
  DateFormatterStyle,
} from '../../utils/format-date-localized/format-date-localized';
import CaseReportStatusComponent from '../../components/case-report-status/case-report-status.component';
import getDiseaseCategoryNameTranslationKey from '../../utils/get-disease-category-translation-key/get-disease-category-name-translation-key';
import getCaseReportTagColors from '../../utils/get-case-report-tag-colors/get-case-report-tag-colors';

export enum CaseReportsTableColumnName {
  NeedsAttention = 'needsAttention',
  Patient = 'patient',
  SubmittedAt = 'submittedAt',
  DiseaseCategory = 'diseaseCategory',
  Assignee = 'assignee',
  Status = 'status',
  Tags = 'tags',
  ManageButtons = 'manageButtons',
}

const useCaseReportsTable = (columnNames: CaseReportsTableColumnName[]) => {
  const { t, i18n } = useTranslation();

  const allColumns: Record<CaseReportsTableColumnName, Column<CaseReport>> = React.useMemo(
    () => ({
      [CaseReportsTableColumnName.NeedsAttention]: {
        Header: '',
        disableSortBy: true,
        id: 'needsAttention',
        accessor: el => el.needsAttention,
        width: 40,
        Cell: ({ value }: { value: boolean }) =>
          value ? <b className="needs-attention-indicator" /> : null,
      },
      [CaseReportsTableColumnName.Patient]: {
        Header: t('CASE_REPORTS.MAIN_TABLE.COLUMNS.PATIENT').toString(),
        disableSortBy: true,
        accessor: el => getInitialsFromName(el.patient?.fullName || ''),
      },
      [CaseReportsTableColumnName.SubmittedAt]: {
        id: 'submittedAt',
        Header: t('CASE_REPORTS.MAIN_TABLE.COLUMNS.SUBMITTED_DATETIME').toString(),
        Cell: ({ value }: { value: Date | null }) =>
          value && formatDateLocalized(value, DateFormatterStyle.Datetime, i18n.resolvedLanguage),
        accessor: el => (el.submittedAt ? new Date(el.submittedAt) : null),
      },
      [CaseReportsTableColumnName.DiseaseCategory]: {
        Header: t('CASE_REPORTS.MAIN_TABLE.COLUMNS.CATEGORY').toString(),
        disableSortBy: true,
        accessor: el => t(getDiseaseCategoryNameTranslationKey(el.diseaseCategory)),
      },
      [CaseReportsTableColumnName.Assignee]: {
        Header: t('CASE_REPORTS.MAIN_TABLE.COLUMNS.ASSIGNEE').toString(),
        disableSortBy: true,
        accessor: el => el.assignee?.fullName,
        Cell: ({ value }: { value: string | undefined }) =>
          value ? (
            <span>{value}</span>
          ) : (
            <span className="text-danger">
              {t('CASE_REPORTS.MAIN_TABLE.COLUMNS.ASSIGNEE.UNASSIGNED')}
            </span>
          ),
      },
      [CaseReportsTableColumnName.Status]: {
        id: 'status',
        Header: t('CASE_REPORTS.MAIN_TABLE.COLUMNS.STATUS').toString(),
        disableSortBy: true,
        Cell: ({ row }: { row: Row<CaseReport> }) => (
          <CaseReportStatusComponent caseReport={row.original} />
        ),
      },
      [CaseReportsTableColumnName.Tags]: {
        id: 'tags',
        Header: t('CASE_REPORTS.MAIN_TABLE.COLUMNS.TAGS'),
        disableSortBy: true,
        accessor: el => el.tags,
        Cell: ({ value }: { value: string[] }) => (
          <div className="d-flex gap-1 flex-wrap ms-3" style={{ width: 120 }}>
            {value.map(tag => {
              const meta = getCaseReportTagColors(tag);
              return (
                <span
                  key={tag}
                  className="badge overflow-hidden"
                  style={{
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    color: meta.color,
                    backgroundColor: meta.bgColor,
                  }}
                >
                  #{tag}
                </span>
              );
            })}
          </div>
        ),
      },
      [CaseReportsTableColumnName.ManageButtons]: {
        id: 'manageButtons',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }: { row: Row<CaseReport> }) => (
          <Link href={`/case-reports/${row.original.id}`}>
            <a>{t('CASE_REPORTS.MAIN_TABLE.COLUMNS.MANAGE_BUTTONS.VIEW')}</a>
          </Link>
        ),
      },
    }),
    [t, i18n]
  );

  const columns = React.useMemo<Column<CaseReport>[]>(
    () => columnNames.map(name => allColumns[name]),
    [allColumns, columnNames]
  );

  const getRowId = React.useCallback((row: CaseReport) => row.id, []);

  return { columns, getRowId };
};

export default useCaseReportsTable;
