import React from 'react';
import { useTranslation } from 'next-i18next';
import { CaseReport, Resolution } from '../../model/entities/case-report.entity';

type CaseReportStatusProps = {
  caseReport: CaseReport;
};

const getCaseReportStatus = (
  caseReport: CaseReport
): { statusI18nKey: string; bgColorClassName: string } => {
  const { resolutionData, completionData } = caseReport;
  if (completionData != null) {
    return {
      statusI18nKey: 'CASE_REPORT_STATUS.COMPLETED',
      bgColorClassName: 'bg-secondary',
    };
  }
  switch (resolutionData?.resolution) {
    case Resolution.Approved:
      return {
        statusI18nKey: 'CASE_REPORT_STATUS.APPROVED',
        bgColorClassName: 'bg-success',
      };
    case Resolution.Rejected:
      return {
        statusI18nKey: 'CASE_REPORT_STATUS.REJECTED',
        bgColorClassName: 'bg-danger',
      };
    default:
      return {
        statusI18nKey: 'CASE_REPORT_STATUS.UNRESOLVED',
        bgColorClassName: 'bg-warning',
      };
  }
};

const CaseReportStatus: React.FC<CaseReportStatusProps> = ({ caseReport }) => {
  const { t } = useTranslation();

  const { statusI18nKey, bgColorClassName } = getCaseReportStatus(caseReport);

  return (
    <span className={`rounded-pill p-2 me-1 mb-1 badge ${bgColorClassName}`}>
      {t(statusI18nKey)}
    </span>
  );
};

export default CaseReportStatus;
